import { useEffect } from 'react';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { match, P } from 'ts-pattern';
import { Typography } from '@leaf/ui';
import { CurrentCompanyMarketDataSummaryQuery } from '@/apollo/generated';
import { getCurrencySymbol } from '@/utils/helper';

function shuffleArray<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While elements remain unshuffled
  while (currentIndex != 0) {
    // Pick a random remaining element
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

let assortedWidths = [
  'w-8',
  'w-16',
  'w-12',
  'w-20',
  'w-24',
  'w-12',
  'w-8',
  'w-16',
];

const Loading = (): JSX.Element => {
  useEffect(() => {
    if (
      firstColumnRows.length !== assortedWidths.length ||
      secondColumnRows.length !== assortedWidths.length
    ) {
      // yell if we ever add data without extending widths array
      throw Error("Widths array length doesn't match number of columns.");
    }

    const interval = setInterval(() => {
      assortedWidths = shuffleArray(assortedWidths);
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="grid w-full grid-cols-1 gap-0 md:grid-cols-2 md:gap-6">
      <div className="flex w-full flex-col">
        {firstColumnRows.map(({ label }, idx) => (
          <div
            key={idx}
            className={`${
              idx % 2 === 0 ? 'bg-hubs-background-accent' : 'bg-white'
            }  flex w-full flex-row items-center justify-between border-b p-2 text-text-secondary`}
          >
            <Typography variant="body-regular">{label}</Typography>
            <span
              className={`${assortedWidths[idx]} my-auto h-6 animate-pulse items-center justify-center rounded bg-gray-300 transition-all duration-500`}
            ></span>
          </div>
        ))}
      </div>
      <div className="flex w-full flex-col">
        {secondColumnRows.map(({ label }, idx) => (
          <div
            key={idx}
            className={`${
              idx % 2 === 0 ? 'bg-hubs-background-accent' : 'bg-white'
            }  flex w-full flex-row items-center justify-between border-b p-2 text-text-secondary`}
          >
            <Typography variant="body-regular">{label}</Typography>
            <span
              className={`${assortedWidths[idx]} my-auto h-6 animate-pulse items-center justify-center rounded bg-gray-300 transition-all duration-500`}
            ></span>
          </div>
        ))}
      </div>
    </div>
  );
};

type CurrentCompanyMarketDataSummary =
  CurrentCompanyMarketDataSummaryQuery['currentCompanyMarketDataSummary'];

interface MarketOverviewDataProps {
  currentCompanyMarketDataSummary: CurrentCompanyMarketDataSummary;
  loading: boolean;
}

type RowData = {
  fields: (keyof Omit<
    NonNullable<CurrentCompanyMarketDataSummary>,
    'isMarketOpen'
  >)[];
  label: string;
};

const earningsPerShareLabel = 'Earnings per share';
const marketCapLabel = 'Market cap';
const priceEarningsRatioLabel = 'Price/earnings ratio';
const sharesOutstandingLabel = 'Shares outstanding';
const turnoverLabel = 'Turnover';
const volumeLabel = 'Volume (no. of shares)';
const yearHighLabel = '52 week high';
const yearLowLabel = '52 week low';

const firstColumnRows: RowData[] = [
  { fields: ['lastTradedPrice'], label: 'Last price' },
  { fields: ['open'], label: 'Open price' },
  { fields: ['dayLow', 'dayHigh'], label: 'Day low - high' },
  { fields: ['priceChange'], label: 'Price change' },
  { fields: ['percentageChange'], label: 'Percentage change' },
  { fields: ['lastClose'], label: 'Previous close' },
  { fields: ['volume'], label: volumeLabel },
  { fields: ['turnover'], label: turnoverLabel },
];

const secondColumnRows: RowData[] = [
  { fields: ['marketCap'], label: marketCapLabel },
  { fields: ['sharesOutstanding'], label: sharesOutstandingLabel },
  { fields: ['vwap'], label: 'VWAP' },
  { fields: ['bid', 'ask'], label: 'Bid / Ask' },
  { fields: ['yearHigh', 'yearHighDate'], label: yearHighLabel },
  { fields: ['yearLow', 'yearLowDate'], label: yearLowLabel },
  { fields: ['priceEarningsRatio'], label: priceEarningsRatioLabel },
  { fields: ['earningsPerShare'], label: earningsPerShareLabel },
];

const marketCapLabels = [marketCapLabel];
const abbreviatedNumberLabels = [sharesOutstandingLabel];
const alwaysTwoDecimals = [earningsPerShareLabel];
const dollarsAndDateLabels = [yearHighLabel, yearLowLabel];
const puncuatedNumberLabels = [volumeLabel];
const puncuatedCurrencyLabels = [turnoverLabel];

const withFallback = (value: string | number | null | undefined): string =>
  `${value}` ?? '-';

const renderRowData = (
  label: string,
  fields: RowData['fields'],
  marketDataSummary: NonNullable<CurrentCompanyMarketDataSummary>
): string | number =>
  match({ fields, label })
    .with(
      { label: P.when((l) => marketCapLabels.includes(l)) },
      ({ fields }) => {
        const value = marketDataSummary[fields[0]];
        if (value == null || value == 0 || typeof value === 'string')
          return '-';

        const isPence = marketDataSummary.currency === 'GBX';
        const valueToUse = isPence ? value / 100 : value;

        return `${getCurrencySymbol(marketDataSummary.currency ?? '')}${numeral(
          valueToUse
        ).format(`0.00a`)}`;
      }
    )
    .with(
      // shares outstanding
      { label: P.when((l) => abbreviatedNumberLabels.includes(l)) },
      ({ fields }) => {
        const value = marketDataSummary[fields[0]];
        if (value == null || value == 0) return '-';

        return `${numeral(value).format(`0,0`)}`;
      }
    )
    .with(
      // 52wk high & low
      { label: P.when((l) => dollarsAndDateLabels.includes(l)) },
      ({ fields }) => {
        const valueOne = marketDataSummary[fields[0]];
        const valueTwo = marketDataSummary[fields[1]];

        if (typeof valueOne === 'number') {
          const dollars = numeral(withFallback(valueOne)).format('0,0.00[0]');

          if (typeof valueTwo === 'string') {
            return `${dayjs(valueTwo).format("(D MMM 'YY)")}
                ${getCurrencySymbol(
                  marketDataSummary.currency ?? ''
                )}${dollars}`;
          }

          return `${getCurrencySymbol(
            marketDataSummary.currency ?? ''
          )}${dollars}`;
        }

        return '-';
      }
    )
    .with(
      // volume
      { label: P.when((l) => puncuatedNumberLabels.includes(l)) },
      ({ fields }) => {
        const count = marketDataSummary[fields[0]];
        const value = marketDataSummary[fields[0]];

        return typeof count === 'number' && value !== 0
          ? (count as number).toLocaleString('en-AU')
          : '-';
      }
    )
    .with(
      // turnover
      { label: P.when((l) => puncuatedCurrencyLabels.includes(l)) },
      ({ fields }) => {
        const value = marketDataSummary[fields[0]];
        const count = marketDataSummary[fields[0]];

        if (count == null || count == 0 || typeof count === 'string')
          return '-';

        const isPence = marketDataSummary.currency === 'GBX';
        const countToUse = isPence ? count / 100 : count;

        return typeof count === 'number' && value !== 0
          ? `${getCurrencySymbol(
              marketDataSummary.currency ?? ''
            )}${countToUse.toLocaleString('en-AU')}`
          : '-';
      }
    )
    .with({ label: 'Percentage change' }, ({ fields }) => {
      const value = marketDataSummary[fields[0]];
      if (value == null) return '-';

      return `${numeral(withFallback(value)).format('0,0.00')}%`;
    })
    .with({ label: 'Price change' }, ({ fields }) => {
      const value = marketDataSummary[fields[0]];
      if (value == null) return '-';
      if (typeof value !== 'number') return '-';

      // this Math.abs is to get final result of -$0.00 instead of $-0.00
      const absValue = Math.abs(value);
      const formattedValue = numeral(absValue).format('0,0.00[0]');
      const currencySymbol = getCurrencySymbol(
        marketDataSummary.currency ?? ''
      );

      return `${value < 0 ? '-' : ''}${currencySymbol}${formattedValue}`;
    })
    .with(
      // price earnings ratio
      { label: P.when((l) => l === priceEarningsRatioLabel) },
      ({ fields }) =>
        marketDataSummary[fields[0]] === 0 ||
        marketDataSummary[fields[0]] == null
          ? '-'
          : `${numeral(
              withFallback(marketDataSummary[fields[0]] as number)
            ).format('0,0.00[0]')}`
    )
    .with(
      // earnings p/share
      { label: P.when((l) => alwaysTwoDecimals.includes(l)) },
      ({ fields }) =>
        marketDataSummary[fields[0]] === 0 ||
        marketDataSummary[fields[0]] == null
          ? '-'
          : `${getCurrencySymbol(marketDataSummary.currency ?? '')}${numeral(
              withFallback(marketDataSummary[fields[0]] as number)
            ).format('0,0.00[0]')}`
    )
    .with(
      // day low - high, bid - ask
      { fields: P.when((fields) => fields.length === 2) },
      ({ fields }) => {
        const valueOne = marketDataSummary[fields[0]];
        const valueTwo = marketDataSummary[fields[1]];
        if (
          valueOne == null ||
          valueTwo == null ||
          valueOne == 0 ||
          valueTwo == 0
        )
          return '-';

        return `${getCurrencySymbol(marketDataSummary.currency ?? '')}${numeral(
          withFallback(valueOne)
        ).format('0,0.00[0]')} - ${getCurrencySymbol(
          marketDataSummary.currency ?? ''
        )}${numeral(withFallback(valueTwo)).format('0,0.00[0]')}`;
      }
    )
    .with(
      // vwap (volume weighted average price) - nick wants 3 decimal places
      { label: 'VWAP' },
      ({ fields }) => {
        const value = marketDataSummary[fields[0]];
        if (value == null || value === 0) return '-';

        return `${getCurrencySymbol(marketDataSummary.currency ?? '')}${numeral(
          withFallback(value as number)
        ).format('0,0.00[0]')}`;
      }
    )
    .with(
      // last price, open price, previous close
      { fields: P.when((fields) => fields.length === 1) },
      ({ fields }) => {
        const value = marketDataSummary[fields[0]];
        if (value == null || value == 0) return '-';

        return `${getCurrencySymbol(marketDataSummary.currency ?? '')}${numeral(
          withFallback(value as number)
        ).format('0,0.00[0]')}`;
      }
    )
    .otherwise(() => '-');

interface TableRowProps {
  idx: number;
  marketDataSummary: NonNullable<CurrentCompanyMarketDataSummary>;
  rowData: RowData;
}

const TableRow = ({
  idx,
  marketDataSummary,
  rowData,
}: TableRowProps): JSX.Element => {
  const { fields, label } = rowData;

  const getChangeColor = (label: string, fields: RowData['fields']) => {
    const affectedLabels = ['Price change', 'Percentage change'];

    if (affectedLabels.includes(label)) {
      const value = marketDataSummary[fields[0]];
      if (value == null || typeof value !== 'number' || value === 0) return '';

      return value > 0
        ? `font-medium text-status-green`
        : `font-medium text-critical-red-default`;
    }

    return '';
  };

  const renderTriangle = (label: string, fields: RowData['fields']) => {
    const affectedLabels = ['Price change', 'Percentage change'];
    const TriangleIcon = ({ className }: { className: string }) => {
      return (
        <svg
          className={className}
          height="6"
          width="8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon points="4,0 8,6 0,6" />
        </svg>
      );
    };
    if (affectedLabels.includes(label)) {
      const value = marketDataSummary[fields[0]];
      if (value == null || typeof value !== 'number') return '';
      if (value === 0) return '';
      return value > 0 ? (
        <TriangleIcon className="fill-status-green" />
      ) : (
        <TriangleIcon className="rotate-180 fill-critical-red-default" />
      );
    }
    return null;
  };

  return (
    <div
      className={`${
        idx % 2 === 0 ? 'bg-hubs-background-accent' : 'bg-white'
      } flex w-full flex-row items-center justify-between p-4 py-2 text-text-secondary first:rounded-t-lg last:rounded-b-lg`}
    >
      <Typography className="font-body" variant="body-regular">
        {label}
      </Typography>
      <Typography
        className={`flex flex-row items-center gap-1 font-body ${getChangeColor(
          label,
          fields
        )}`}
        variant="body-regular"
      >
        {renderTriangle(label, fields)}
        {renderRowData(label, fields, marketDataSummary)}
      </Typography>
    </div>
  );
};

export const MarketOverviewTable = ({
  currentCompanyMarketDataSummary,
  loading,
}: MarketOverviewDataProps): JSX.Element | null => {
  if (loading || !currentCompanyMarketDataSummary) {
    return <Loading />;
  }

  return (
    <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2">
      <div className="flex flex-col gap-4">
        <Typography
          className="font-semibold text-hubs-secondary"
          variant="system-regular"
        >
          Price Activity
        </Typography>
        <div className="flex w-full flex-col divide-y divide-gray-200 rounded-lg border border-gray-200">
          {firstColumnRows.map((rowData, idx) => (
            <TableRow
              key={idx}
              idx={idx}
              marketDataSummary={currentCompanyMarketDataSummary}
              rowData={rowData}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <Typography
          className="font-semibold text-hubs-secondary"
          variant="system-regular"
        >
          Performance
        </Typography>
        <div className="flex w-full flex-col divide-y divide-gray-200 rounded-lg border border-gray-200">
          {secondColumnRows.map((rowData, idx) => (
            <TableRow
              key={idx}
              idx={idx}
              marketDataSummary={currentCompanyMarketDataSummary}
              rowData={rowData}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
